.App {
  text-align: center;
}

.Text {
  min-height: 100px;
  min-width:  100px;
  text-align: center;
  background-color: #61dafb;
}

.App-logo {
  height: 30vmin;
  pointer-events: none;
  margin: 30px;
}


.nftCard {
  width: 256px;
  pointer-events: none;
  margin-bottom: 10px;
}

.nftCardText {
  height: 18px;
  text-align: center;
}

.normalText {
  height: 36x;
  text-align: center;
  margin: 5px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  background-image: url("./../images/bg2.jpg");
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  min-width: 140px;
  min-height: 50px;
  margin-bottom: 10px;
  margin-top: 40px;
  color: #eecd10;
  background-color: #282c34;
  box-shadow: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: scale(100px);
  }
  to {
    transform: scale(40px);
  }
}
